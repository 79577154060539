.nav-list{
    list-style: none;
    display: flex;
    padding: 0px;
    margin: 0px;
}

.nav-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo{
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color:orange;
    margin: 4px;
}

.burgerbtn{
    display: none;
    width: 100vw;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.burgbutton:focus{
    outline: none!important
}

.line{
    height: 4px;
    width: 32px;
    margin: 4px;
}

.small-line{
    height: 6px;
    width:16px;
    margin: 4px;
}

.burgbutton{
    background: none;
    border: none;
}

.nav-list-item{
    padding: 6px;
    text-align : center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link{
    text-decoration: none;
}

@media screen and (max-width: 1100px) {
    .nav-list{
        list-style: none;
        display: none;
        padding-bottom:16px;
    }
    .burgerbtn{
        display: flex;
    }
    .block{
        display: block!important;
    }
    .nav-container{
        display: block;
    }
    .last{
        padding-left: 6px;
    }
    .menu_logo{
        margin-left:10px;
    }
}
