.temporary-img{
    background-color: white;
    box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.15);
    height: 75vh;
    width: 90vw;
}

.home-div{
    height: 70vh;
    width: 35vw;
}

.side-div{
    display: flex;
    position: absolute;
    left: 0;
    width: 5vw;
    height: 50vh;
    justify-content: center;
    align-items: center;
}

.first-div-home{
    height: 90vh;
    width: 99.65vw;
    background-color:white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-icon-home{
    width: 1.5vw;
}

.home-icon{
    margin-top: 8vh;
    margin-bottom: 8vh;
    margin-left:0vw;
    margin-right:0vw;
}

.social-bar{
    display: none;
}

@media screen and (max-width: 1100px) {
    .temporary-img{
        width: 100vw;
    }
    .home-div{
        height: 35vh;
        width: 80vw;
    }
    .side-div{
        display: none;
    }

    .social-icon-home{
        width: 40px;
    }

    .social-bar{
        display: block;
    }

    .home-icon{
        margin-top: 2vh;
        margin-bottom: 2vh;
        margin-left:4vw;
        margin-right:4vw;
    }

}