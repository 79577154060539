.list{
    list-style: none;
    margin: 0;
    display: block;
    padding: 0;
}

.list-item{
    padding: 8px;
    max-width: 20vw;;
}

.last{
    margin-right: 5vw;
}

.first{
    margin-left: 5vw;
}

.rounded{
    border-radius: 200%;
}

.shadow {
    box-shadow: 0 1px 6px 0px rgba(0, 0, 0, 0.45);
}

.space-between{
    display: flex;
    justify-content: space-between;
}

.block{
    display: block;
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.header{
    text-align: center;
    background-color: #BDBDBD;
    padding: 8px;
}

.flex{
    display: flex;
}

.fluid{
    display: flex;
}

.box{
    margin: 8px;
}

.color-steelblue{
    color: lightsteelblue;
}

.color-aqua{
    color: aqua;
}

.color-orange{
    color: orange;
}

.color-red{
    color: red;
}

.color-black{
    color: black;
}

.color-gray{
    color: gray;
}

.color-white{
    color: white;
}

.color-dark{
    color: rgb(175, 172, 170);
}

.color-dark-gray{
    color: #4d4c4c;
}


.color-gd{
    color:#ffac53;
}

.bg-color-orange {
    background-color: orange;
}

.bg-color-red{
    background-color: #f44336;
}

.bg-color-black{
    background-color:black;
}

.bg-color-white{
    background-color:white;
}

.bg-color-dark-black{
    background-color: #212121;
}

.bg-color-green{
    background-color:rgb(16, 216, 76);
}

.bg-color-gd{
    background-color:#ffac53;
}

.color-hover:hover{
    color: #2ea3f2!important;
}

.align-center{
    display: flex;
    justify-content: center;
}

.link{
    text-decoration: none;
}

.button{
    border: none;
    padding-right: 16px;
    padding-left: 16px;
    padding-top:8px;
    padding-bottom:8px;
    color: white;
    margin: 8px;
    border-radius: 2px;
}

.button:hover{
    box-shadow: 0px 0px 0px 0px gray;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.input{
    margin: 8px;
    padding: 16px;
    border: #ffac53 solid 2px;
    border-radius: 5px;
    resize: none;
}

.input:focus{
    outline: none;
}

.txt-area{
    width: 50vw;
    height: 20vh;
}

.menu_logo{
    max-height: 60px;
    display: block;
}

.pd-sm{
    padding: 1vh;
}

.footer {
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
}

.dist-1{
    margin-bottom: 2vh;
}

.text-align-center{
    text-align: center;
}

.txt-s{
    font-size:1.3rem;
}

.w-10{
   width: 10vw; 
}

.w-20{
    width: 20vw; 
 }

.w-50{
width: 50vw; 
}

.w-100{
width: 99.5vw; 
}

.pd-4{
    padding: 4px;
}

.pd-1v{
    padding: 1vw;
}

.m-sm{
    margin: 8px;
}

.mb-2vh{
    margin-bottom: 2vh;
}

.img-sm{
    width: 50px;
}

.card{
    border: #ffac53 solid 4px;
    border-radius: 5px;
    width: 40vw;
    padding:0;
    margin: 0;
}

.post-paragraph{
    width: 40vw;
    padding:0;
    margin: 0;
}

.card-sm{
    border: #ffac53 solid 4px;
    border-radius: 5px;
    width: 20vw;
    padding:0;
    margin: 0;
}

.cut-text { 
    overflow:hidden;
  }

.image_st_post{
    width: 40vw;
}

.image_st_post-sm{
    width: 20vw;
}

.mini_logo{
    height: 100px;
    margin-top: -48px;
}

.mini_logo_sx{
    height: 8vh;
    position: absolute;
}


.pd-50{
    padding-bottom: 50px;
}

.extend-100{
    min-height: 100%;
    min-width: 100%;
}

.alone{
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.align-center-h{
    align-items: center;
}

.max-100{
    max-width: 100%;
    max-height:100%;
}

.max-img-30{
    max-height: 30%;
}

.bg-top-absolute{
    position: absolute;
    top:0;
    left: 0;
    z-index: -1;
}

.bg-home{
    height: 100vh;
    width: 100vw;
}

.br-gd{
    border: #ffac53 3px solid;
}

.max-img-fluid{
    max-height: 30%;
}

.about-text{
    background-color:#ffac53;
    margin: 1vw;
    padding: 15px;
    width: 40vw;
}

.text-align-left{
    text-align: left;
}

.absolute{
    position: absolute;
}

.about-txt{
    padding: 8px;
    max-width: 40vw;
    border: #ffac53 solid 2px;
}

@media screen and (max-width: 1100px) {
    .fluid{
        display: block;
    }

    .box{
        margin: 0;
    }
    .container_logo{
        max-height: 100px;
        display: none;
    }
    .first{
        margin-left: 0vw;
    }
    .last{
        margin-right: 0;
    }
    .w-10{
        width: 90%; 
     }
     
     .w-20{
         width: 90%; 
      }
     
     .w-50{
     width: 90%; 
     }
     
     .w-100{
     width: 100vw; 
     }
     .image_st_post{
        width: 90vw;
    }
    .card{
        width: 90vw;
    }
    .post-paragraph{
        width: 90vw;
    }
    .image_st_post-sm{
        width: 90vw;
    }
    .card-sm{
        width: 90vw;
    }
       
    .pd-50{
        padding-bottom: 50px;
    }

    .max-img-fluid{
        max-height: 55%;
    }

    .about-text{

        width: 90vw;
    }

    .about-txt{
        padding: 8px;
        max-width: 90vw;
    }
}


/* width */
::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: white; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #212121; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #333333; 
  }