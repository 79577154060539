.img-people{
    max-height: 120px;
    margin: 8px;
}

.contact-card{
    border: solid 2px #ffac53;
    border-radius: 12px;
    padding: 8px;
    max-width: 45vw;
    margin: 2vw;
}

@media screen and (max-width: 1100px) {
    .contact-card{
        border: solid 2px #ffac53;
        border-radius: 12px;
        padding: 8px;
        max-width: 99vw;
    }
}