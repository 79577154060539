.f-block{
    margin-left:8vw;
    margin-right:8vw;
}

.f-logo{
    max-width: 50px;
    margin-right: 16px;
}

hr { display: block; height: 1px;
    border: 0; border-top: 1px solid #4d4c4c;
    margin: 1em 0; padding: 0; }

.foot{
    min-width: 50vw;
}


.social-icon-footer{
    width: 50px;
}
